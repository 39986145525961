<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 72 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      opacity="0.5"
      cx="36"
      cy="36.5"
      r="36"
      fill="#FFF1EE"
    />
    <circle
      cx="36.5"
      cy="37"
      r="29.5"
      fill="#FFF1EE"
    />
    <path
      d="M43.4261 40.3679H42.0687L41.5876 39.9048C43.2715 37.9494 44.2852 35.4108 44.2852 32.6492C44.2852 26.4914 39.2852 21.5 33.1168 21.5C27.2406 21.5 22.4296 26.0283 22 31.7916H25.4708C25.9003 27.9322 29.1306 24.9305 33.1168 24.9305C37.3952 24.9305 40.8488 28.3782 40.8488 32.6492C40.8488 36.9202 37.3952 40.3679 33.1168 40.3679C32.8247 40.3679 32.5498 40.3165 32.2577 40.2822V43.747C32.5498 43.7813 32.8247 43.7985 33.1168 43.7985C35.8832 43.7985 38.4261 42.7864 40.3849 41.1055L40.8488 41.5858V42.9408L49.4399 51.5L52 48.9443L43.4261 40.3679Z"
      fill="#F23F44"
    />
    <path
      d="M30.6666 34.7733L29.7266 33.8333L25.9999 37.56L22.2733 33.8333L21.3333 34.7733L25.0599 38.5L21.3333 42.2267L22.2733 43.1667L25.9999 39.44L29.7266 43.1667L30.6666 42.2267L26.9399 38.5L30.6666 34.7733Z"
      fill="#F23F44"
      stroke="#F23F44"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcSearchPositiveEnabled',
  props: {
    size: {
      type: [String, Number],
      default: 72,
    },
  },
}
</script>
